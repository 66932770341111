body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.start-screen {
  box-sizing: border-box;
  background: #292929;
  margin: 0;
  /* min-height:80vh; */
  /* padding: 50px; */
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-screen .container {
  background-image: url('/src/img/border.svg');
  /* max-width: 720px;
    height: 945px;
    height: 100vh; */
  width: calc(100% - 75px);
  height: calc(100% - 75px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  background-position: center;
  /* background-size: cover; */
  background-size: contain;
  /* background-size: calc(100% - 50px); */
  background-repeat: no-repeat;
}

.start-screen .container .logo {
  background-image: url('/src/img/logo.svg');
  width: 190.71px;
  height: 176.58px;
  transition: all .4s;
  background-position: center;
  background-size: cover;
  animation: zoom 5s infinite;

}

.start-screen .container .text {
  font-size: 2em;
  line-height: 1em;
  text-align: center;
  font-weight: 600;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  padding-bottom: 60px;
  padding-top: 60px;
  width: 100%;
  max-width: calc(100% - 75px);
}


.start-screen .container .button {
  border: #8BFFA1 2px solid;
  color: #8BFFA1;
  padding: 10px 25px;
  background-color: transparent;
  cursor: pointer;
  transition: all .4s;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
}

.start-screen .container .button:hover {
  background-color: #8BFFA1;
  color: #292929;
}


/* The animation code */
@keyframes zoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}



@media only screen and (max-width: 750px) {
  .start-screen .container .logo {
    width: 140.71px;
    height: 126.58px;
  }

  .start-screen .container .text {
    font-size: 1.5em;
  }
}
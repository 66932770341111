.preview-container {
    box-sizing: border-box;
    background: #292929;
    margin: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: 'Source Sans Pro', sans-serif;
}

.preview-container__print-preview-wrapper {
    position: absolute;
    left: 0%;
    top: 0%;
    z-index: -100;
}

.preview-container__print-preview {
    display: flex;
}

.preview-container__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #292929;
}

.preview-container__preview-back {
    position: relative;
    width: 105px;
    height: 153px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
    z-index: 12;
    cursor: pointer;
}

.preview-container__preview-back::before {
    content: "";
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top-width: 69px;
    border-bottom-width: 69px;
    border-right: 105px solid #ff8b8b;
    background-color: transparent;
    left: 0;
    top: 0;
    z-index: 10;
    transition: all .4s;
}

.preview-container__preview-back:hover:before {
    border-right: 105px solid #ac5c5c;
}

.preview-container__preview-back::after {
    content: "";
    background-image: url('../../img/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 11;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50px;
    top: 60px;
}

.preview-container__preview-print {
    position: relative;
    width: 105px;
    height: 153px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.7);
    z-index: 12;
    cursor: pointer;
}

.preview-container__preview-print::before {
    content: "";
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 69px;
    border-top-width: 69px;
    border-left: 105px solid #8BFFA1;
    background-color: transparent;
    left: 0;
    top: 0;
    z-index: 10;
    transition: all .4s;
}

.preview-container__preview-print:hover:before {
    border-left: 105px solid #66b975;
}

.preview-container__preview-print::after {
    content: "";
    background-image: url('../../img/check.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 11;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 20px;
    top: 60px;
}

.preview-container__preview-container {
    max-width: 250px;
}

.preview-container__preview-container img {
    width: 100%;
}

.preview-container__preview-container-text {
    font-size: 1.8em;
    line-height: 1em;
    text-align: center;
    font-weight: 600;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    padding-top: 30px;
}



@media only screen and (max-width: 750px) {
    .preview-container__preview-container-text {
        font-size: 1.5em;
    }
}
.photo-container {
    box-sizing: border-box;
    background: #292929;
    margin: 0;
    min-height: 80vh;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    overflow: hidden;
    font-family: 'Source Sans Pro', sans-serif;
}

.photo-container .container {
    background-color: #fff;
    /* height: 100%; */
    max-width: 750px;
    max-width: 650px;
    /* max-width: calc(100% - 75px); */
    /* max-height: 798px; */
    max-height: calc(100% - 75px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-position: center;
    background-size: cover;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50px;
    position: relative;
}

.photo-container .container .nav {
    background-color: #F2F14C;
    width: 100%;
    height: 180px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-container .container .nav span {
    width: 75px;
    height: 75px;
    max-width: 75px;
    max-height: 75px;
    background-color: #fff;
    border-radius: 50%;
    border: solid #292929 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-weight: 700;
    margin: 0 5px;
    opacity: .5;
    transition: all .2s;
}

.photo-container .container .nav span.active {
    opacity: 1;
}

.photo-container .container .icon {
    position: absolute;
    bottom: calc(-175px / 2);
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    border: solid #292929 6px;
    background-size: 70%;
    background-image: url('/src/img/logo-dark.svg');
    background-position: center;
    background-repeat: no-repeat;
    transition: all .3s;
}

.photo-container .container .icon.active {
    background-color: #8afda1;
}

.text {
    font-size: 35px;
    text-align: center;
    font-weight: 600;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    padding-top: 100px;
}


.webcam-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.webcam-container .webcam-btn {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    display: none;
}

.webcam-container .film-strip_wrapper-preview {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -100;
}

.webcam-container .film-strip_wrapper-preview .film-strip_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 600px;
    min-height: 1200px;
}

.webcam-container .film-strip_wrapper-preview .film-strip_wrapper .film-strip {
    position: relative;
    transform: scale(1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
}

.webcam-container .film-strip_wrapper-preview .film-strip_wrapper .film-strip li {
    list-style: none;
    background-color: #fff;
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.webcam-container .film-strip_wrapper-preview .film-strip_wrapper .film-strip li:first-child {
    margin-top: 25px;
}

.webcam-container .film-strip_wrapper-preview .film-strip_wrapper .film-strip li img {
    width: 100%;
    display: flex;
    min-width: 250px;
}

.webcam-container .film-strip-preview {
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(.15);
    transform-origin: right top;
}

.webcam-container .webcam-img {
    position: relative;
    overflow: hidden;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    width: 100%;
    height: 100%;
    transition: all .1s;
}

.webcam-container .webcam-img.active {
    opacity: 0;
}

.webcam-container .webcam-img video {
    /* transform: scale(1.2);
    position: absolute; */
    /* min-height: 400px; */
    position: relative;
    height: 100%;
    width: 100%;
    left: -5%;
    top: 0;
    transform-origin: center;
    transform: scale(1.2);
}


@media only screen and (max-width: 750px) {
    .text {
        font-size: 25px;
    }
}